import { defineStore } from 'pinia';
import axios from "axios";
import type {CustomerForm} from "~/types/Customer";
import type {ApiUser, AuthResponse, UserPayloadInterface} from "~/types/User";
import type {GenerateResetTokenResponse} from "~/types/Authenticate";

export const useAuthStore = defineStore('auth', {
    state: () => ({
        authenticated: false,
        loading: false,
        user: null as ApiUser | null,
        _storeFromProductPagePromise: null as Promise<any> | null,
    }),
    actions: {
        async login({ email, password, token }: UserPayloadInterface) {
            try {
                const formData = new FormData();
                formData.append('email', email);
                formData.append('password', password);
                formData.append('token', token || '');

                const response = await axios.post(`${import.meta.env.VITE_API_BASE_URL}/api/v1/auth/login`, formData);
                if (response.data.data) {
                    const result = response.data.data;
                    if (result.access_token) {
                        await this.setUser(result);
                    }
                }
            } catch (error) {
                throw error;
            }
        },
        async storeUserFromProductPage(customerForm: CustomerForm, productId: number, token: string|undefined) {
            if (this._storeFromProductPagePromise) return this._storeFromProductPagePromise;
             this._storeFromProductPagePromise = (async () => {
                 try {
                     const response = await axios.post(`${import.meta.env.VITE_API_BASE_URL}/api/v1/users/store-from-product-page`, {
                         customer: customerForm,
                         product_id: productId,
                         token,
                     });
                     if (response.data.data) {
                         const result = response.data.data;
                         if (result.access_token) {
                             await this.setUser(result);
                         }
                         return result;
                     }
                 } catch (error) {
                     throw error;
                 }
             })();
            return this._storeFromProductPagePromise;
        },
        async loginWithGoogle(token: string) {
            try {
                const response = await axios.post(`${import.meta.env.VITE_API_BASE_URL}/api/v1/auth/social/authentications/google`, { token });
                if (response.data.data) {
                    const result = response.data.data;
                    if (result.access_token) {
                        await this.setUser(result);
                    }
                    return result;
                }
            } catch (error) {
                throw error;
            }
        },
        async checkEmailExists(email: string, token: string|undefined): Promise<boolean> {
            try {
                const response = await axios.post(`${import.meta.env.VITE_API_BASE_URL}/api/v1/users/email-existence-check`,
                    {
                        email,
                        token,
                    });
                if (response.data) {
                    return response.data.alreadyExists;
                }
            } catch (error) {
                throw error;
            }
            return false;
        },
        async logout() {
            const authResponse = useCookie('authResponse'); // useCookie new hook in nuxt 3
            this.authenticated = false; // set authenticated state value to false
            authResponse.value = null; // clear the user cookie
            this.user = null; // clear user state
        },
        async setUser(data: any) {
            const authResponse = useCookie('authResponse');
            authResponse.value = data;
            this.authenticated = true;
            this.user = data.user;
        },
        getUser(): ApiUser | null {
            if (this.user) return this.user;
            const authResponse = useCookie('authResponse');
            if (typeof authResponse.value === 'object'
                && authResponse.value) {
                const authResponseData = authResponse.value as AuthResponse;
                this.user = authResponseData.user;
            }
            return this.user;
        },
        getAccessToken(): string | null {
            const authResponse = useCookie('authResponse');
            let accessToken = null;
            if (typeof authResponse.value === 'object'
                && authResponse.value) {
                const authResponseData = authResponse.value as AuthResponse;
                accessToken = authResponseData.access_token;
            }
            return accessToken;
        },
        async generateResetToken(email: string, token: string): Promise<GenerateResetTokenResponse|null> {
            try {
                const response = await axios.post(`${import.meta.env.VITE_API_BASE_URL}/api/v1/auth/generate-reset-token`, {
                    email,
                    token,
                });
                return response.data.data;
            } catch (error) {
                throw error;
            }
        },
        async resetPassword(email: string, password: string, passwordConfirmation: string, resetToken: string, token: string) {
            try {
                const response = await axios.post(`${import.meta.env.VITE_API_BASE_URL}/api/v1/auth/reset-password`, {
                    email,
                    reset_token: resetToken,
                    password,
                    password_confirmation: passwordConfirmation,
                    token: token
                });
                const result = response.data.data;
                if (result.access_token) {
                    await this.setUser(result);
                }
                return result;
            } catch (error) {
                throw error;
            }
        },
        async register(email: string,
                       phoneNumber: string,
                       lastName: string,
                       firstName: string,
                       password: string,
                       passwordConfirmation: string,
                       isDefaultPassword: boolean,
                       token: string) {
            try {
                const response = await axios.post(`${import.meta.env.VITE_API_BASE_URL}/api/v1/auth/register`, {
                    email,
                    phone_number: phoneNumber,
                    last_name: lastName,
                    first_name: firstName,
                    password,
                    password_confirmation: passwordConfirmation,
                    is_default_password: isDefaultPassword,
                    token,
                });
                const result = response.data.data;
                if (result.access_token) {
                    await this.setUser(result);
                }
                return result;
            } catch (error) {
                throw error;
            }
        }
    },
});
